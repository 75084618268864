<template>
    <el-cascader
        ref="cascader"
        v-model="model"
        placeholder="请输入地名以搜索"
        :options="options"
        :props="{ emitPath: false, value: 'code', label: 'name', multiple, checkStrictly:checkStrictly}"
        v-on="$listeners"
        v-bind="$attrs"
        filterable>
        <template slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
        </template>
    </el-cascader>
</template>

<script>
import {ChineseDistricts, Province} from './lib/city-picker.data';
import {deepCopy} from "@/util/objects";

const options = Province.map((obj) => {
    let key = Object.keys(obj)[0];
    return {code: key, name: obj[key]};
});
const optionsCity = deepCopy(options);
options.forEach(province => {
    let cities = ChineseDistricts[province.code];
    let provinceCity = optionsCity.find(o => o.code === province.code);
    if (cities) {
        province.children = Object.keys(cities).map(key => ({code: key, name: cities[key]}));
        provinceCity.children = deepCopy(province.children);
        province.children.forEach(city => {
            let counties = ChineseDistricts[city.code];
            if (counties) {
                city.children = Object.keys(counties).map(key => ({code: key, name: counties[key]}));
            }
        })
    }
});

export default {
    name: "CityPicker",
    data() {
        return {
            options: [],
        }
    },
    computed: {
        model: {
            get() {
                return this.value;
            },
            set(newVal, oldVal) {
                this.$emit('input', newVal);
                this.$nextTick(() => {
                    const checkedNodes = this.$refs.cascader.getCheckedNodes();
                    if (checkedNodes.length) {
                        const nodeNames = checkedNodes.map(node => {
                            const names = [node.data.name];
                            while (node.parent) {
                                names.unshift(node.parent.data.name);
                                node = node.parent;
                            }
                            return names.join(this.nameSeparator);
                        });
                        this.$emit('update:name', nodeNames.length === 1 ? nodeNames[0] : nodeNames);
                    } else {
                        this.$emit('update:name', null);
                    }
                })
            }
        }
    },
    watch: {
        province: {
            handler(val) {
                let opts = this.city ? optionsCity : options;
                if (val) {
                    this.options = opts.filter(o => val.indexOf(o.code) > -1);
                } else {
                    this.options = opts;
                }
            },
            immediate: true
        }
    },
    props: {
        value: String,
        multiple: {
            type: Boolean,
            default: false
        },
        nameSeparator: {
            type: String,
            default: " / "
        },
        checkStrictly: {
            type: Boolean,
            default: false
        },
        city: {
            type: Boolean,
            default: false
        },
        province: String
    }
}
</script>

<style scoped>

</style>
